/*
 * tweet style
 */
 $content-width:    800px !default;

 $on-palm:          600px !default;
 $on-laptop:        800px !default;

 @mixin media-query($device) {
   @media screen and (max-width: $device) {
     @content;
   }
 }

.tw-block-parent {
  font: 14px/1.4 Roboto, Helvetica, Arial, Tahoma, sans-serif;
  font-weight: 300;
  color: #292F33;
  background-color: #fff;
  border-bottom: 1px solid #E6ECFA;
}

.avatar {
  width: 60px;
  height: 60px;
  background: url('/assets/avatar.png');
  background-size: 60px;
}

.timeline-tweetlist-tweet {
  border-top: 1px solid rgba(15, 70, 100, 0.12);
  &:first-of-type {
    border-top: none;
  }
}

.timeline-tweet {
  position: relative;
  padding: 10px;
  a, .link {
    color: #3b94d9;
    text-decoration: none;
    outline: 0;
  }
  a:hover, .link:hover {
    color: #55acee;
  }
}

.timeline-tweet-brand {
  position: absolute;
  top: 10px;
  right: 10px;
}

.timeline-tweet-author {
  position: relative;
  margin-bottom: 2px;
  padding-left: 68px;

  @include media-query($on-palm) {
    padding-left: 0px;
  }
}

.timeline-tweet-text {
  margin-bottom: 12px;
  word-wrap: break-word;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-left: 68px;

  @include media-query($on-palm) {
    margin-left: 0px;
  }
}

.timeline-tweet-metadata {
  position: absolute;
  bottom: 12px;
  right: 10px;
}

.timeline-tweet-actions {
  margin: 0 0 0 40px;
  padding: 0;
  list-style: none;
  border: none;
}

.timeline-tweet-action {
  display: inline-block;
  font-size: 17px;
  + .timeline-tweet-action {
    margin-left: 20px;
  }
}

.timeline-tweet-timestamp {
  font-size: 14px;
  line-height: 18px;
  color: #e1e8ed;
  transition: color .2s;
}

.timeline-tweet:hover {
  background-color: rgba(160, 200, 220, 0.12);
  .timeline-tweet-action .Icon {
    opacity: 1;
  }
  .timeline-tweet-timestamp {
    color: #667580;
  }
}

.tweetauthor {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.tweetauthor-link {
  font-weight: inherit;
  color: inherit;
  text-decoration: inherit;
}

.tweetauthor-avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 30px;

  @include media-query($on-palm) {
    display: None;
  }
}

.tweetauthor-name {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}
