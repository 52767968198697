// Credit goes to https://github.com/xieranmaya/blog/issues/4
.section {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

section::after {
  content: '';
  flex-grow: 999999999;
}

.div {
  margin: 2px;
  background-color: violet;
  position: relative;
  background-color: violet;
}

.i {
  display: block;
}

.img {
  position: absolute;
  top: 0;
  width: 100%;
  vertical-align: bottom;
}
