/**
 * Site header
 */
.site-header {
  // border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: 56px;
  background-color: #fff;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  font-size: 26px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
  float: right;
  line-height: 56px;

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg path {
        fill: $grey-color-dark;
      }
    }

    .trigger {
      clear: both;
      display: none;
    }

    &:hover .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: ($spacing-unit / 2) 0;
  background-color: #e6ecf0;
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width: calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width: calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width: calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width: calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  background-color: #e6ecf0;
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit / 2;
  }
}

.post {
  background-color: #fff;
  border-bottom: 1px solid #e6ecfa;
}

.post:hover {
  background-color: #f5f8fa;
  cursor: pointer;
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  font-size: 24px;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  display: block;
  font-size: 24px;

  @include media-query($on-laptop) {
    font-size: 24px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}
