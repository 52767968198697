$gray: #999;
$lightGray: #eee;

.posts {
	list-style-type: none;
	padding: 0;
	>li {
		margin-top: 30px;
		margin-bottom: 30px;
		padding-bottom: 20px;
		&:not(:last-child) {
			border-bottom: 1px solid $lightGray;
		}
	}
}

.post-meta {
	display: flex;
	justify-content: space-between;
	align-items: center;
	a:hover {
		border-bottom: none;
	}
}

.post-title {
	color: #222;
	transition: color .3s ease;
	margin: .1em 0;
	&:hover {
		color: $gray;
		border-bottom: 0;
	}
}
.post-date {
	margin-left: 12px;
}
